import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const GameList = React.lazy(() => import("@/pages/Game/GameList"));
const GameCreate = React.lazy(() => import("@/pages/Game/GameCreate"));
const GameEdit = React.lazy(() => import("@/pages/Game/GameEdit"));

export const GAME_ROUTES = {
  games: {
    path: "games",
    childRoutes: {
      gameList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.game],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <GameList />,
      },
      gameCreate: {
        path: "create",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.game],
          operations: [RESOURCE_OPERATIONS.create],
        },
        element: <GameCreate />,
      },
      gameEdit: {
        path: ":gameId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.game],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <GameEdit />,
      },
    },
  },
};
