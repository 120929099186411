import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const GameKeyDiffList = React.lazy(
  () => import("@/pages/GameKey/GameKeyDiffList")
);
const GameKeyDiff = React.lazy(() => import("@/pages/GameKey/GameKeyDiff"));

export const GAME_KEY_ROUTES = {
  gameKeys: {
    path: "game-keys",
    childRoutes: {
      gameKeyDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.gameKey],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <GameKeyDiffList />,
      },
      gameKeyDiff: {
        path: ":gameKeyDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.gameKey],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <GameKeyDiff />,
      },
    },
  },
};
