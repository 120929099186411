import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

import { api } from "./api";
import { setUsername } from "../../features/auth/authSlice";
import { enqueueSnackbar } from "../../features/notifier/notifierSlice";
import { AuthenticationError } from "../../models/response";
import { Session, SessionResponse } from "../../models/session";
import isFetchBaseQueryError from "../../utils/isFetchBaseQueryError";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_AUTH_API_URL,
  }),
  endpoints: (build) => ({
    login: build.mutation<Session, { username: string; password: string }>({
      query: ({ username, password }) => {
        const params = new URLSearchParams({
          username,
          password,
          grant_type: "password",
          client_id: import.meta.env.VITE_AUTH_API_CLIENT_ID,
        });

        return {
          url: `realms/${
            import.meta.env.VITE_AUTH_API_REALM
          }/protocol/openid-connect/token`,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: params.toString(),
        };
      },
      onQueryStarted: async ({ username }, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          // Set username on successful login
          dispatch(setUsername(username));

          // Invalidate api state so all data is re-fetched
          dispatch(api.util.resetApiState());

          // Notify user
          dispatch(
            enqueueSnackbar({
              message: "Logged in successfully!",
              options: {
                key: "login_success",
                variant: "success",
              },
            })
          );
        } catch (e: any) {
          // Notify user
          let error: AuthenticationError | undefined;
          if ("error" in e && isFetchBaseQueryError(e.error)) {
            const data = e.error.data as AuthenticationError;
            if (data) {
              error = data;
            }
          }

          dispatch(
            enqueueSnackbar({
              message:
                error?.error_description ??
                "An error occurred while logging in.",
              options: {
                key: "login_error",
                variant: "error",
              },
            })
          );
        }
      },
      extraOptions: {
        backoff: () => {
          // Don't retry login requests
          retry.fail({ fake: "error" });
        },
      },
      transformResponse: handleSessionResponse,
    }),
    refreshLogin: build.mutation<Session, string>({
      query: (refresh_token) => {
        const params = new URLSearchParams({
          refresh_token,
          grant_type: "refresh_token",
          client_id: import.meta.env.VITE_AUTH_API_CLIENT_ID,
        });

        return {
          url: `realms/${
            import.meta.env.VITE_AUTH_API_REALM
          }/protocol/openid-connect/token`,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: params.toString(),
        };
      },
      extraOptions: {
        backoff: () => {
          // Don't retry login requests
          retry.fail({ fake: "error" });
        },
      },
      transformResponse: handleSessionResponse,
    }),
  }),
});

export const { useLoginMutation } = authApi;

export const {
  endpoints: { login, refreshLogin },
} = authApi;

function handleSessionResponse(response: SessionResponse) {
  const expires_in = new Date();
  expires_in.setSeconds(expires_in.getSeconds() + response.expires_in);

  const refresh_expires_in = new Date();
  refresh_expires_in.setSeconds(
    refresh_expires_in.getSeconds() + response.refresh_expires_in
  );

  return {
    access_token: response.access_token,
    expires_in: expires_in.toString(),
    refresh_expires_in: refresh_expires_in.toString(),
    refresh_token: response.refresh_token,
    token_type: response.token_type,
    not_before_policy: response.not_before_policy,
    session_state: response.session_state,
    scope: response.scope.split(" "),
  };
}
