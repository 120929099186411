import { RootState, store } from "@/app/store";
import { selectUserPermissions } from "@/features/auth/authSlice";
import { selectAppConfig } from "@/features/general/generalSlice";
import {
  Environment,
  RESOURCE_OPERATIONS,
  Resource,
  ResourceOperation,
} from "@/models/user";

export interface HasPermissionArgs {
  resources?: Resource[];
  envs?: Environment[];
  operations?: ResourceOperation[];
  games?: string[];
  superUserRequired?: boolean;
  adminRequired?: boolean;
  activeGameCheck?: boolean;
}

/**
 * Method to check if the user have access to perform operations on requested resources
 *
 * @param args.resources - Array of resources to check permissions for
 * @param args.operations - Array of operations to check for all resources
 * @param args.envs - Array of environments to check
 * @param args.games - Array of game code to check permissions for
 * @param args.superUserRequired - Whether super user permission is required for the operation
 * @param args.adminRequired - Whether admin permission is required for the operation
 * @param args.activeGameCheck - Whether games permission should be checked
 *
 * @returns Whether the user can perform the operations on requested resources
 */
export const hasPermission = ({
  resources = [],
  envs = [],
  games = [],
  operations = [],
  superUserRequired = false,
  adminRequired = false,
  activeGameCheck = true,
}: HasPermissionArgs = {}): boolean => {
  const state = store.getState() as RootState;
  const activeGameCode = selectAppConfig(state).activeGame?.code || "";
  const permissions = selectUserPermissions(state);

  if (!permissions) {
    return false;
  }

  const {
    superuser,
    admin,
    games: permittedGames,
    objects,
    environments,
  } = permissions;

  if (!superuser && superUserRequired) {
    return false;
  }

  if (superuser || admin) {
    return true;
  }

  if (!admin && adminRequired) {
    return false;
  }

  if (activeGameCheck) {
    if (!(activeGameCode in permittedGames)) {
      return false;
    }

    if (
      !operations.every((operation) =>
        permittedGames[activeGameCode].includes(operation)
      )
    ) {
      return false;
    }

    if (!permittedGames[activeGameCode].includes(RESOURCE_OPERATIONS.read)) {
      return false;
    }
  }

  const hasGameAccess = games.every((game) => {
    const hasAllOperationAccess = operations.every((operation) =>
      permittedGames[game]?.includes(operation)
    );

    return (
      game in permittedGames &&
      permittedGames[game]?.includes(RESOURCE_OPERATIONS.read) &&
      hasAllOperationAccess
    );
  });

  if (!hasGameAccess) {
    return false;
  }

  const hasResourcesAccess = resources.every((resource) => {
    // Every requested resource should have access for each operation
    const hasAllOperationsAccess = operations.every((operation) =>
      objects[resource]?.includes(operation)
    );

    return (
      resource in objects &&
      // For every operation read operation is always required
      objects[resource]?.includes(RESOURCE_OPERATIONS.read) &&
      hasAllOperationsAccess
    );
  });

  if (!hasResourcesAccess) {
    return false;
  }

  return envs.every((env) => {
    // Every requested env should have access for each operation
    const hasAllOperationAccess = operations.every((operation) =>
      environments[env]?.includes(operation)
    );

    return (
      env in environments &&
      // For every operation read operation is always required
      environments[env]?.includes(RESOURCE_OPERATIONS.read) &&
      hasAllOperationAccess
    );
  });
};
