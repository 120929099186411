import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const TimedShopSelectorOverrideDiffList = React.lazy(
  () =>
    import(
      "@/pages/TimedShopSelectorOverride/TimedShopSelectorOverrideDiffList"
    )
);
const TimedShopSelectorOverrideDiff = React.lazy(
  () =>
    import("@/pages/TimedShopSelectorOverride/TimedShopSelectorOverrideDiff")
);

export const TIMED_SHOP_SELECTOR_OVERRIDE_ROUTES = {
  timedShopSelecterOverrides: {
    path: "timed-shop-selector-overrides",
    childRoutes: {
      timedShopSelecterOverrideDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.timedShopSelectorOverride],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TimedShopSelectorOverrideDiffList />,
      },
      timedShopSelectorOverrideDiff: {
        path: ":timedShopSelectorOverrideDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.timedShopSelectorOverride],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TimedShopSelectorOverrideDiff />,
      },
    },
  },
};
