import React from "react";

const ApiDirectory = React.lazy(
  () => import("@/pages/ApiDirectory/ApiDirectory"),
);

export const ENDPOINTS_ROUTES = {
  endpoints: {
    path: "admin",
    childRoutes: {
      endpointList: {
        path: "endpoints",
        isProtected: true,
        permissionProps: {
          superUserRequired: true,
        },
        element: <ApiDirectory />,
      },
    },
  },
};
