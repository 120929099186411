import { useRef } from "react";

/**
 * This hook returns the last value before it was changed
 * to the current latest value.
 * @param value - The initial value
 * @returns The previous value
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<{
    target: T;
    previous: T | undefined;
  }>({ target: value, previous: undefined });

  if (ref.current.target !== value) {
    ref.current.previous = ref.current.target;
    ref.current.target = value;
  }

  return ref.current.previous;
};
