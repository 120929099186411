import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const TaskDiffList = React.lazy(() => import("@/pages/Task/TaskDiffList"));
const TaskDiff = React.lazy(() => import("@/pages/Task/TaskDiff"));

export const TASK_ROUTES = {
  tasks: {
    path: "tasks",
    childRoutes: {
      taskDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.task],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TaskDiffList />,
      },
      taskDiff: {
        path: ":taskDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.task],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TaskDiff />,
      },
    },
  },
};
