import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const EpisodeDiffList = React.lazy(
  () => import("@/pages/Episode/EpisodeDiffList")
);
const EpisodeDiff = React.lazy(() => import("@/pages/Episode/EpisodeDiff"));

export const EPISODE_ROUTES = {
  episodes: {
    path: "episodes",
    childRoutes: {
      episodeDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.episode],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <EpisodeDiffList />,
      },
      episodeDiff: {
        path: ":episodeDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.episode],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <EpisodeDiff />,
      },
    },
  },
};
