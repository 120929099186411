import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const MessageTemplateDiffList = React.lazy(
  () => import("@/pages/MessageTemplate/MessageTemplateDiffList")
);
const MessageTemplateDiff = React.lazy(
  () => import("@/pages/MessageTemplate/MessageTemplateDiff")
);

export const MESSAGE_TEMPLATE_ROUTES = {
  messageTemplates: {
    path: "message-templates",
    childRoutes: {
      messageTemplateDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.messageTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <MessageTemplateDiffList />,
      },
      messageTemplateDiff: {
        path: ":messageTemplateDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.messageTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <MessageTemplateDiff />,
      },
    },
  },
};
