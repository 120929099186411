import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const CountryDiffList = React.lazy(
  () => import("@/pages/Country/CountryDiffList")
);
const CountryDiff = React.lazy(() => import("@/pages/Country/CountryDiff"));

export const COUNTRY_ROUTES = {
  countries: {
    path: "countries",
    childRoutes: {
      countryDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.country],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <CountryDiffList />,
      },
      countryDiff: {
        path: ":countryDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.country],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <CountryDiff />,
      },
    },
  },
};
