import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const AssetTypeDiffList = React.lazy(
  () => import("@/pages/AssetType/AssetTypeDiffList")
);
const AssetTypeDiff = React.lazy(
  () => import("@/pages/AssetType/AssetTypeDiff")
);

export const ASSET_TYPE_ROUTES = {
  assetTypes: {
    path: "asset-types",
    childRoutes: {
      assetTypeDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.assetType],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <AssetTypeDiffList />,
      },
      assetTypeDiff: {
        path: ":assetTypeDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.assetType],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <AssetTypeDiff />,
      },
    },
  },
};
