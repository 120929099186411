import { InstanceWrapper, InstancesWrapper } from "./response";

export const RESOURCES = {
  user: "user",
  role: "role",
  country: "country",
  eventSchedule: "event_schedule",
  ladderTemplate: "ladder_template",
  ladderEvent: "ladder_event",
  transactionModifierTemplate: "transaction_modifier_template",
  transactionModifierEvent: "transaction_modifier_event",
  tileTemplate: "tile_template",
  tileEvent: "tile_event",
  recurringLoginTemplate: "recurring_login_template",
  gameKey: "game_key",
  gameSetting: "game_setting",
  segment: "segment",
  currency: "currency",
  product: "product",
  timedOffer: "timed_offer",
  shopSelector: "shop_selector",
  timedShopSelectorOverride: "timed_shop_selector_override",
  shop: "shop",
  messageTemplate: "message_template",
  announcement: "announcement",
  announcementOrder: "announcement_order",
  currencyQuestTemplate: "currency_quest_template",
  currencyQuestBoardTemplate: "currency_quest_board_template",
  game: "game",
  platform: "platform",
  app: "app",
  assetType: "asset_type",
  asset: "asset",
  episode: "episode",
  episodeOrder: "episode_order",
  task: "task",
  level: "level",
  levelOrder: "level_order",
  levelTemplate: "level_template",
  migratorUser: "migrator_user",
  migratorRole: "migrator_role",
} as const;

export const RESOURCE_OPERATIONS = {
  read: "read",
  create: "create",
  update: "update",
  delete: "delete",
} as const;

export const ENVIRONMENTS = {
  migrator: "MIGRATOR",
  dev: "DEV",
  uat: "UAT",
  staging: "STAGING",
  live: "LIVE",
} as const;

export type Resource = (typeof RESOURCES)[keyof typeof RESOURCES];
export type Environment = (typeof ENVIRONMENTS)[keyof typeof ENVIRONMENTS];
export type AppEnvironment = Exclude<Environment, "MIGRATOR">;

export type ResourceOperation = keyof typeof RESOURCE_OPERATIONS;

export interface UserRole {
  ids: string[];
  access: {
    superuser: boolean;
    admin: boolean;
    games: Record<string, ResourceOperation[]>;
    objects: Record<Resource, ResourceOperation[]>;
    environments: Record<Environment, ResourceOperation[]>;
  };
}

export interface UserRoleReference {
  name: string;
}

export interface User {
  created_on: string;
  updated_on: string;
  iam_id: string;
  object_id: string;
  object_type: "migrator_user";
  username: string;
}

export interface GetUserListResponse {
  migrator_user: InstancesWrapper<Record<string, User>>;
  migrator_role: {
    ids_per_user: Record<string, string[]>;
    refs: Record<string, UserRoleReference>;
  };
}

export interface GetCurrentUserResponse {
  migrator_user: InstanceWrapper<User>;
  migrator_role: UserRole;
}
