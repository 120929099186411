import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const EpisodeOrderDiffList = React.lazy(
  () => import("@/pages/EpisodeOrder/EpisodeOrderDiffList")
);
const EpisodeOrderDiff = React.lazy(
  () => import("@/pages/EpisodeOrder/EpisodeOrderDiff")
);

export const EPISODE_ORDER_ROUTES = {
  episodeOrder: {
    path: "episode-order",
    childRoutes: {
      episodeOrderDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.episodeOrder],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <EpisodeOrderDiffList />,
      },
      episodeOrderDiff: {
        path: ":episodeOrderDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.episodeOrder],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <EpisodeOrderDiff />,
      },
    },
  },
};
