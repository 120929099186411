import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const AppDiffList = React.lazy(() => import("@/pages/App/AppDiffList"));
const AppDiff = React.lazy(() => import("@/pages/App/AppDiff"));

export const APP_ROUTES = {
  apps: {
    path: "apps",
    childRoutes: {
      appDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.app],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <AppDiffList />,
      },
      appDiff: {
        path: ":appDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.app],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <AppDiff />,
      },
    },
  },
};
