import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackbarKey } from "notistack";

import { Notification, NotificationPayload } from "./notification";
import { RootState } from "../../app/store";

export type State = {
  notifications: Notification[];
};

const initialState: State = {
  notifications: [],
};

const slice = createSlice({
  name: "notifier",
  initialState,
  reducers: {
    enqueueSnackbar: (state, action: PayloadAction<NotificationPayload>) => {
      const { options = {}, ...rest } = action.payload;
      const key = options.key ?? Date.now() + Math.random();
      const variant = options.variant ?? "default";

      state.notifications = [
        ...state.notifications,
        {
          ...rest,
          dismissed: false,
          options: {
            ...options,
            key,
            variant,
          },
        },
      ];
    },
    closeSnackbar: (state, action: PayloadAction<SnackbarKey | void>) => ({
      ...state,
      notifications: state.notifications.map((notification) => {
        if (
          action.payload === undefined ||
          notification.options.key === action.payload
        ) {
          return {
            ...notification,
            dismissed: true,
          };
        }

        return notification;
      }),
    }),
    removeSnackbar: (state, action: PayloadAction<SnackbarKey>) => ({
      ...state,
      notifications: state.notifications.filter(
        (notification) => notification.options.key !== action.payload
      ),
    }),
  },
});

export default slice.reducer;

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = slice.actions;

export const selectNotifications = (state: RootState) =>
  state.notifier.notifications;
