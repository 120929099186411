import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const GameSettingDiffList = React.lazy(
  () => import("@/pages/GameSetting/GameSettingDiffList")
);
const GameSettingDiff = React.lazy(
  () => import("@/pages/GameSetting/GameSettingDiff")
);

export const GAME_SETTING_ROUTES = {
  gameSettings: {
    path: "game-settings",
    childRoutes: {
      gameSettingDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.gameSetting],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <GameSettingDiffList />,
      },
      gameSettingDiff: {
        path: ":gameSettingDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.gameSetting],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <GameSettingDiff />,
      },
    },
  },
};
