import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const TileTemplateDiffList = React.lazy(
  () => import("@/pages/TileTemplate/TileTemplateDiffList")
);
const TileTemplateDiff = React.lazy(
  () => import("@/pages/TileTemplate/TileTemplateDiff")
);

export const TILE_TEMPLATE_ROUTES = {
  tileTemplates: {
    path: "tile-templates",
    childRoutes: {
      tileTemplateList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.tileTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TileTemplateDiffList />,
      },
      tileTemplateDiff: {
        path: ":tileTemplateDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.tileTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TileTemplateDiff />,
      },
    },
  },
};
