import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const UserList = React.lazy(() => import("@/pages/User/UserList"));

export const USER_ROUTES = {
  users: {
    path: "users",
    childRoutes: {
      userList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.migratorUser],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <UserList />,
      },
    },
  },
};
