import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const CurrencyQuestTemplateDiffList = React.lazy(
  () => import("@/pages/CurrencyQuestTemplate/CurrencyQuestTemplateDiffList")
);
const CurrencyQuestTemplateDiff = React.lazy(
  () => import("@/pages/CurrencyQuestTemplate/CurrencyQuestTemplateDiff")
);

export const CURRENCY_QUEST_TEMPLATE_ROUTES = {
  currencyQuestTemplate: {
    path: "currency-quest-templates",
    childRoutes: {
      currencyQuestTemplateList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.currencyQuestTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <CurrencyQuestTemplateDiffList />,
      },
      currencyQuestTemplateDiff: {
        path: ":currencyQuestTemplateDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.app],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <CurrencyQuestTemplateDiff />,
      },
    },
  },
};
