import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const CurrencyQuestBoardTemplateDiffList = React.lazy(
  () =>
    import(
      "@/pages/CurrencyQuestBoardTemplate/CurrencyQuestBoardTemplateDiffList"
    )
);
const CurrencyQuestBoardTemplateDiff = React.lazy(
  () =>
    import("@/pages/CurrencyQuestBoardTemplate/CurrencyQuestBoardTemplateDiff")
);

export const CURRENCY_QUEST_BOARD_TEMPLATE_ROUTES = {
  currencyQuestBoardTemplate: {
    path: "currency-quest-board-template",
    childRoutes: {
      currencyQuestBoardTemplateList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.currencyQuestBoardTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <CurrencyQuestBoardTemplateDiffList />,
      },
      appDiff: {
        path: ":currencyQuestBoardTemplateDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.currencyQuestBoardTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <CurrencyQuestBoardTemplateDiff />,
      },
    },
  },
};
