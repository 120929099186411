import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const AssetDiffList = React.lazy(() => import("@/pages/Asset/AssetDiffList"));
const AssetDiff = React.lazy(() => import("@/pages/Asset/AssetDiff"));

export const ASSET_ROUTES = {
  assets: {
    path: "assets",
    childRoutes: {
      assetDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.asset],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <AssetDiffList />,
      },
      assetEdit: {
        path: ":assetDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.asset],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <AssetDiff />,
      },
    },
  },
};
