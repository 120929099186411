import {
  BaseQueryArg,
  BaseQueryExtraOptions,
} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { BaseQueryFn, FetchArgs } from "@reduxjs/toolkit/dist/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/src/query/baseQueryTypes";
import cloneDeep from "lodash.clonedeep";
import merge from "lodash.merge";

import { RootState } from "@/app/store";
import { selectAppConfig } from "@/features/general/generalSlice";

const withBackendHandling =
  <BaseQuery extends BaseQueryFn>(baseQuery: BaseQuery) =>
  async (
    args: BaseQueryArg<BaseQuery>,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQuery>
  ) => {
    // Setup ready to modify args
    let adjustedArgs: FetchArgs;

    // noinspection SuspiciousTypeOfGuard
    if (typeof args === "string") {
      adjustedArgs = { url: args };
    } else {
      adjustedArgs = { ...args };
    }

    // Clone FormData if needed
    if (adjustedArgs.body instanceof FormData) {
      adjustedArgs.body = new FormData();
      for (const [key, value] of adjustedArgs.body.entries()) {
        adjustedArgs.body.append(key, value);
      }
    }

    if (adjustedArgs.params) {
      // Clone params if exist
      adjustedArgs.params = cloneDeep(adjustedArgs.params);
    } else {
      // Initialise params
      adjustedArgs.params = {};
    }

    // Initialise headers
    adjustedArgs.headers = new Headers(adjustedArgs.headers ?? ({} as any));

    const state = api.getState() as RootState;
    const appConfig = selectAppConfig(state);

    // Get timestamp, iam and/or game_code if already set, otherwise use defaults
    const timestamp =
      getFromRequest("timestamp", adjustedArgs) ?? Date.now().toString();
    const iam =
      getFromRequest("iam", adjustedArgs) ??
      state.auth.access_token ??
      undefined;
    const game_code =
      getFromRequest("game_code", adjustedArgs) ??
      appConfig.activeGame?.code ??
      "global";

    const sourceEnv =
      getFromRequest("source_environment", adjustedArgs) ??
      appConfig.activeEnvs.source;
    const targetEnv =
      getFromRequest("target_environment", adjustedArgs) ??
      appConfig.activeEnvs.target;

    // Data to add to request
    const additionalData = {
      timestamp,
      iam,
      game_code,
      source_environment: sourceEnv,
      target_environment: targetEnv,
      request_data: {
        timestamp,
      },
    };

    // Add to request
    addToRequest(additionalData, adjustedArgs);

    // Stringify any objects/arrays in params
    for (const [key, value] of Object.entries(adjustedArgs.params)) {
      if (typeof value === "object" && value !== null) {
        adjustedArgs.params[key] = JSON.stringify(value);
      }
    }

    if (!(adjustedArgs.body instanceof FormData) && adjustedArgs.body) {
      if ("request_data" in adjustedArgs.body) {
        adjustedArgs.body.request_data = JSON.stringify(
          adjustedArgs.body.request_data
        );
      }
    }

    // Make request
    return baseQuery(adjustedArgs, api, extraOptions);
  };

export default withBackendHandling;

function getFromRequest(field: string, args: FetchArgs) {
  if (args.body) {
    if (args.body instanceof FormData && args.body.has(field)) {
      return args.body.get(field);
    } else if (typeof args.body === "string") {
      const params = new URLSearchParams(args.body);
      if (params.has(field)) {
        return params.get(field);
      }
    } else if (typeof args.body === "object" && args.body[field]) {
      return args.body[field];
    }
  }

  if (args.params && args.params[field]) {
    return args.params[field];
  }

  return undefined;
}

function addToRequest(fieldsToAdd: Record<string, any>, args: FetchArgs) {
  const requestHasBody = !!args.method && args.method !== "GET";
  if (!requestHasBody) {
    // No body, so add to params

    args.params = merge(args.params, fieldsToAdd);

    return;
  }

  // Body exists, so add to body where possible

  if (args.body instanceof FormData) {
    // Body is FormData

    for (const [key, value] of Object.entries(fieldsToAdd)) {
      if (typeof value === "object") {
        args.params![key] = value;
      }

      args.body.append(key, value);
    }
  } else if (typeof args.body === "string") {
    // Body is URLSearchParams

    const params = new URLSearchParams(args.body);
    for (const [key, value] of Object.entries(fieldsToAdd)) {
      if (typeof value === "object") {
        args.params![key] = value;
      }

      params.append(key, value);
    }

    args.body = params.toString();
  } else if (args.body) {
    // Body is JSON

    args.body = merge(cloneDeep(args.body), fieldsToAdd);
  } else {
    // Body is undefined

    args.body = {
      ...fieldsToAdd,
    };
  }
}
