import { ENDPOINTS_ROUTES } from "@/routes/endpoints";
import { GAME_ROUTES } from "@/routes/game";
import { ROLE_ROUTES } from "@/routes/role";
import { USER_ROUTES } from "@/routes/user";

export const GENERAL_ROUTES = {
  ...USER_ROUTES,
  ...ENDPOINTS_ROUTES,
  ...GAME_ROUTES,
  ...ROLE_ROUTES,
};
