import { QueryFulfilledRejectionReason } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseQueryFn, FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { API_URLS, api } from "@/app/services/api";
import { handleLogout, setRoleData } from "@/features/auth/authSlice";
import { enqueueSnackbar } from "@/features/notifier/notifierSlice";
import { Response } from "@/models/response";
import {
  ENVIRONMENTS,
  GetCurrentUserResponse,
  GetUserListResponse,
} from "@/models/user";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserList: build.query<GetUserListResponse, void>({
      query: () => ({
        url: `${API_URLS.migrator.root}/user`,
        params: {
          game_code: "global",
          source_environment: ENVIRONMENTS.migrator,
          target_environment: ENVIRONMENTS.migrator,
        },
      }),
      transformResponse: (response: Response<GetUserListResponse>) =>
        response.resources,
      providesTags: (result) => [
        ...Object.values(result?.migrator_user.instances ?? {}).map(
          ({ object_id }) => ({ type: "MigratorUser", id: object_id } as const)
        ),
        { type: "MigratorUser" as const, id: "LIST" },
      ],
    }),
    getCurrentUser: build.query<GetCurrentUserResponse, void>({
      query: () => ({
        url: "/migrator/user/me",
        params: {
          game_code: "global",
          source_environment: ENVIRONMENTS.migrator,
          target_environment: ENVIRONMENTS.migrator,
        },
      }),
      transformResponse: (response: Response<GetCurrentUserResponse>) =>
        response.resources,
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(setRoleData(data.migrator_role));
        } catch (error) {
          if (
            (
              (
                (error as QueryFulfilledRejectionReason<BaseQueryFn>)
                  ?.error as FetchBaseQueryError
              )?.data as Response<Object>
            )?.code !== "BCA.0022"
          ) {
            dispatch(
              enqueueSnackbar({
                message: "An error occurred while fetching user data.",
                options: {
                  key: "fetch_current_user_data_error",
                  variant: "error",
                },
              })
            );
          }

          dispatch(handleLogout());
          console.error(error);
        }
      },
    }),
  }),
});

export const {
  useLazyGetCurrentUserQuery,
  useGetUserListQuery,
  useGetCurrentUserQuery,
} = userApi;
