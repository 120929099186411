import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const CurrencyDiffList = React.lazy(
  () => import("@/pages/Currency/CurrencyDiffList")
);
const CurrencyDiff = React.lazy(() => import("@/pages/Currency/CurrencyDiff"));

export const CURRENCY_ROUTES = {
  currencies: {
    path: "currencies",
    childRoutes: {
      currencyDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.currency],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <CurrencyDiffList />,
      },
      currencyDiff: {
        path: ":currencyDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.currency],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <CurrencyDiff />,
      },
    },
  },
};
