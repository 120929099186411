import React from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import { useGetCurrentUserQuery } from "@/app/services/user";
import { APP_SECTIONS } from "@/constants/appSections";
import { usePrevious } from "@/hooks/usePrevious";
import { GENERAL_ROUTES } from "@/routes/general";
import { renderRoutes } from "@/routes/renderRoutes";

import { useAppSelector } from "./app/hooks";
import { storePersistor } from "./app/store";
import ProtectedRoute from "./components/ProtectedRoute";
import { selectIsAuthenticated } from "./features/auth/authSlice";
import Notifier from "./features/notifier/Notifier";
import { MIGRATOR_ROUTES } from "./routes/migratorRoutes";
import Theme from "./theme/Theme";
import useTokenExpireLogout from "./utils/useTokenExpireLogout";

const Login = React.lazy(() => import("@/pages/Login/Login"));
const ErrorPage = React.lazy(() => import("@/pages/Error/ErrorPage"));
const Layout = React.lazy(() => import("@/components/Layout/Layout"));

function App() {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  useGetCurrentUserQuery(undefined, {
    skip:
      !isAuthenticated ||
      (isAuthenticated && prevLocation?.pathname === "/login"),
    refetchOnMountOrArgChange: true,
  });

  useTokenExpireLogout();

  return (
    <Theme>
      <PersistGate loading={null} persistor={storePersistor}>
        <Notifier>
          <React.Suspense>
            <Routes>
              <Route path="/login" element={<Login />} />

              <Route
                path="/migrator/:source/:target/:gameCode"
                element={
                  <ProtectedRoute
                    hasPermission={isAuthenticated}
                    noPermissionPath="/login"
                  >
                    <Layout />
                  </ProtectedRoute>
                }
              >
                {renderRoutes(MIGRATOR_ROUTES)}
              </Route>
              <Route
                path="/general"
                element={
                  <ProtectedRoute
                    hasPermission={isAuthenticated}
                    noPermissionPath="/login"
                  >
                    <Layout />
                  </ProtectedRoute>
                }
              >
                {renderRoutes(GENERAL_ROUTES)}
              </Route>
              <Route
                path="/"
                element={<Navigate to={`/${APP_SECTIONS.general}`} />}
              />
              <Route
                path="*"
                element={<ErrorPage error="Page not found..." />}
              />
            </Routes>
          </React.Suspense>
        </Notifier>
      </PersistGate>
    </Theme>
  );
}

export default App;
