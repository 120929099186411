import React, { useEffect } from "react";

import { SnackbarKey, SnackbarProvider, useSnackbar } from "notistack";

import { Notification } from "./notification";
import { removeSnackbar, selectNotifications } from "./notifierSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

let displayed: SnackbarKey[] = [];

type Props = {
  children: React.ReactNode;
};

export default function Notifier({ children }: Props) {
  return (
    <SnackbarProvider maxSnack={3}>
      <NotificationRenderer />

      {children}
    </SnackbarProvider>
  );
}

function NotificationRenderer() {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (key: SnackbarKey) => {
    displayed = [...displayed, key];
  };

  const removeDisplayed = (key: SnackbarKey) => {
    displayed = [...displayed.filter((k) => key !== k)];
  };

  useEffect(() => {
    notifications.forEach(
      ({ message, options, dismissed = false }: Notification) => {
        const { key } = options as { key: SnackbarKey };

        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) {
          return;
        }

        // display snackbar using notistack
        enqueueSnackbar(message, {
          ...options,
          onClose: (event, reason, key) => {
            if (options.onClose) {
              options.onClose(event, reason, key);
            }
          },
          onExited: (event, key) => {
            // remove this snackbar from redux store
            dispatch(removeSnackbar(key));
            removeDisplayed(key);
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}
