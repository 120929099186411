import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const TransactionModifierTemplateDiffList = React.lazy(
  () =>
    import(
      "@/pages/TransactionModifierTemplate/TransactionModifierTemplateDiffList"
    )
);
const TransactionModifierTemplateDiff = React.lazy(
  () =>
    import(
      "@/pages/TransactionModifierTemplate/TransactionModifierTemplateDiff"
    )
);

export const TRANSACTION_MODIFIER_TEMPLATE_ROUTES = {
  transactionModifierTemplates: {
    path: "transaction-modifier-templates",
    childRoutes: {
      transactionModifierTemplateDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.transactionModifierTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TransactionModifierTemplateDiffList />,
      },
      transactionModifierTemplateDiff: {
        path: ":transactionModifierTemplateDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.transactionModifierTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TransactionModifierTemplateDiff />,
      },
    },
  },
};
