import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const ProductDiffList = React.lazy(
  () => import("@/pages/Product/ProductDiffList")
);
const ProductDiff = React.lazy(() => import("@/pages/Product/ProductDiff"));

export const PRODUCT_ROUTES = {
  products: {
    path: "products",
    childRoutes: {
      productDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.product],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <ProductDiffList />,
      },
      productDiff: {
        path: ":productDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.product],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <ProductDiff />,
      },
    },
  },
};
