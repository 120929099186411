import {
  BaseQueryArg,
  BaseQueryExtraOptions,
} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { BaseQueryFn, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/src/query/baseQueryTypes";
import { Mutex } from "async-mutex";

import { handleLogout } from "../../../features/auth/authSlice";
import { enqueueSnackbar } from "../../../features/notifier/notifierSlice";
import { AuthenticationError, Response } from "../../../models/response";
import isFetchBaseQueryError from "../../../utils/isFetchBaseQueryError";
import { RootState } from "../../store";
import { refreshLogin } from "../auth";

// Don't allow concurrent refreshes
const mutex = new Mutex();

const withTokenRefresh =
  <BaseQuery extends BaseQueryFn>(baseQuery: BaseQuery) =>
  async (
    args: BaseQueryArg<BaseQuery>,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQuery>
  ) => {
    // Wait for any in-progress refreshes to finish
    await mutex.waitForUnlock();

    // Attempt the query
    let result = await baseQuery(args, api, extraOptions);

    // If no error, return the result
    const error = result.error as FetchBaseQueryError;
    if (!error) {
      return result;
    }

    // If the error is not a 418, return the result
    if (error.status !== 418) {
      return result;
    }

    // If the error is a 418, check the response code
    const response = error.data as Response<never>;
    if (response.code !== "BCA.0022") {
      return result;
    }

    // If the error is a 418 with the correct response code, check if we have a refresh token
    let state = api.getState() as RootState;
    const { refresh_token } = state.auth;
    if (!refresh_token) {
      return result;
    }

    if (mutex.isLocked()) {
      // If mutex is locked, let the other request handle the refresh

      await mutex.waitForUnlock();

      // If the refresh failed, don't retry the request
      state = api.getState() as RootState;
      if (state.auth.access_token) {
        result = await baseQuery(args, api, extraOptions);
      }
    } else {
      // Otherwise, lock mutex and refresh the token

      const release = await mutex.acquire();

      try {
        const refreshResult = await api.dispatch(
          refreshLogin.initiate(refresh_token)
        );
        if ("error" in refreshResult) {
          // If the refresh failed, logout
          api.dispatch(handleLogout());

          // Notify user
          if (isFetchBaseQueryError(refreshResult.error)) {
            const data = refreshResult.error.data as AuthenticationError;
            if (data) {
              api.dispatch(
                enqueueSnackbar({
                  message:
                    data.error === "invalid_grant"
                      ? "Login session expired, logging you out..."
                      : data.error_description ??
                        "An error occurred while refreshing your login.",
                  options: {
                    key: "login_refresh_error",
                    variant: "error",
                  },
                })
              );
            }
          }

          return result;
        }

        // Otherwise, retry the original request
        result = await baseQuery(args, api, extraOptions);
      } finally {
        release();
      }
    }

    return result;
  };

export default withTokenRefresh;
