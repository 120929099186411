import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const PlatformDiffList = React.lazy(
  () => import("@/pages/Platform/PlatformDiffList")
);
const PlatformDiff = React.lazy(() => import("@/pages/Platform/PlatformDiff"));

export const PLATFORM_ROUTES = {
  platforms: {
    path: "platforms",
    childRoutes: {
      platformDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.platform],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <PlatformDiffList />,
      },
      platformDiff: {
        path: ":platformDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.platform],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <PlatformDiff />,
      },
    },
  },
};
