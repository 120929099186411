import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const LevelDiffList = React.lazy(() => import("@/pages/Level/LevelDiffList"));
const LevelDiff = React.lazy(() => import("@/pages/Level/LevelDiff"));

export const LEVEL_ROUTES = {
  levels: {
    path: "levels",
    childRoutes: {
      levelDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.level],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LevelDiffList />,
      },
      levelDiff: {
        path: ":levelDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.level],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LevelDiff />,
      },
    },
  },
};
