import React from "react";

import { Path } from "history";
import { Navigate, useLocation } from "react-router-dom";

import { APP_SECTIONS } from "@/constants/appSections";
import { GLOBAL_SELECTION } from "@/features/general/generalSlice";

type Props = {
  hasPermission: boolean;
  children: React.ReactNode;
  noPermissionPath?: string;
};

export default function ProtectedRoute({
  hasPermission,
  children,
  noPermissionPath = "/",
}: Props) {
  const location = useLocation();

  let returnPath: string | Path = location;

  if (location.pathname === "/login") {
    returnPath = `/${APP_SECTIONS.general}/${GLOBAL_SELECTION.code}`;
  }

  if (!hasPermission) {
    return <Navigate to={noPermissionPath} state={{ returnPath }} />;
  }

  return <>{children}</>;
}
