import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const RoleList = React.lazy(() => import("@/pages/Role/RoleList"));
const RoleCreate = React.lazy(() => import("@/pages/Role/RoleCreate"));
const RoleEdit = React.lazy(() => import("@/pages/Role/RoleEdit"));

export const ROLE_ROUTES = {
  roles: {
    path: "roles",
    childRoutes: {
      roleList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.migratorRole],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <RoleList />,
      },
      roleCreate: {
        path: "create",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.migratorRole],
          operations: [RESOURCE_OPERATIONS.create],
        },
        element: <RoleCreate />,
      },
      roleEdit: {
        path: ":roleId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.migratorRole],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <RoleEdit />,
      },
    },
  },
};
