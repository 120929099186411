import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const LadderTemplateDiffList = React.lazy(
  () => import("@/pages/LadderTemplate/LadderTemplateDiffList")
);
const LadderTemplateDiff = React.lazy(
  () => import("@/pages/LadderTemplate/LadderTemplateDiff")
);

export const LADDER_TEMPLATE_ROUTES = {
  ladderTemplates: {
    path: "ladder-templates",
    childRoutes: {
      ladderTemplateDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.ladderTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LadderTemplateDiffList />,
      },
      ladderTemplateDiff: {
        path: ":ladderTemplateDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.ladderTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LadderTemplateDiff />,
      },
    },
  },
};
