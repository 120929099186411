import { ReactNode } from "react";

import { Route, PathRouteProps, Outlet } from "react-router-dom";

import ProtectedRoute from "@/components/ProtectedRoute";
import { APP_SECTIONS } from "@/constants/appSections";
import { GLOBAL_SELECTION } from "@/features/general/generalSlice";
import { HasPermissionArgs, hasPermission } from "@/utils/hasPermission";

interface AppRoute {
  index?: boolean;
  path?: string;
  isProtected?: boolean;
  permissionProps?: HasPermissionArgs;
  element?: ReactNode;
  childRoutes?: Record<string, AppRoute>;
}

export const renderRoutes = (routes: Record<string, AppRoute>): ReactNode => {
  return Object.entries(routes).map(
    ([
      id,
      { path, index, isProtected, childRoutes, element, permissionProps },
    ]) => {
      if (isProtected) {
        return (
          <Route
            key={id}
            index={index as PathRouteProps["index"]}
            path={path}
            element={
              <ProtectedRoute
                hasPermission={hasPermission(
                  permissionProps as HasPermissionArgs
                )}
                noPermissionPath={`/${APP_SECTIONS.general}/${GLOBAL_SELECTION.code}`}
              >
                {childRoutes && Object.keys(childRoutes).length ? (
                  <Outlet />
                ) : (
                  element
                )}
              </ProtectedRoute>
            }
          >
            {childRoutes && Object.keys(childRoutes).length
              ? renderRoutes(childRoutes)
              : undefined}
          </Route>
        );
      }

      return (
        <Route
          key={id}
          path={path}
          index={index as PathRouteProps["index"]}
          element={element}
        >
          {childRoutes && Object.keys(childRoutes).length
            ? renderRoutes(childRoutes)
            : undefined}
        </Route>
      );
    }
  );
};
