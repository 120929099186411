import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const ShopDiffList = React.lazy(() => import("@/pages/Shop/ShopDiffList"));
const ShopDiff = React.lazy(() => import("@/pages/Shop/ShopDiff"));

export const SHOP_ROUTES = {
  shops: {
    path: "shop",
    childRoutes: {
      shopDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.shop],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <ShopDiffList />,
      },
      shopDiff: {
        path: ":shopDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.shop],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <ShopDiff />,
      },
    },
  },
};
