import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const LevelTemplateDiffList = React.lazy(
  () => import("@/pages/LevelTemplate/LevelTemplateDiffList")
);
const LevelTemplateDiff = React.lazy(
  () => import("@/pages/LevelTemplate/LevelTemplateDiff")
);

export const LEVEL_TEMPLATE_ROUTES = {
  levelTemplates: {
    path: "level-templates",
    childRoutes: {
      levelTemplateDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.levelTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LevelTemplateDiffList />,
      },
      levelTemplateDiff: {
        path: ":levelTemplateDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.levelTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LevelTemplateDiff />,
      },
    },
  },
};
