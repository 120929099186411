import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const RoleDiffList = React.lazy(() => import("@/pages/RoleDiff/RoleDiffList"));
const RoleDiff = React.lazy(() => import("@/pages/RoleDiff/RoleDiff"));

export const ROLE_DIFF_ROUTES = {
  rolesDiff: {
    path: "roles-diff",
    childRoutes: {
      roleDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.role],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <RoleDiffList />,
      },
      roleDiff: {
        path: ":roleDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.role],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <RoleDiff />,
      },
    },
  },
};
