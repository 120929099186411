import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const ShopSelectorDiffList = React.lazy(
  () => import("@/pages/ShopSelector/ShopSelectorDiffList")
);
const ShopSelectorDiff = React.lazy(
  () => import("@/pages/ShopSelector/ShopSelectorDiff")
);

export const SHOP_SELECTOR_ROUTES = {
  shopSelectors: {
    path: "shop-selectors",
    childRoutes: {
      shopSelectorDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.shopSelector],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <ShopSelectorDiffList />,
      },
      shopSelectorDiff: {
        path: ":shopSelectorDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.shopSelector],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <ShopSelectorDiff />,
      },
    },
  },
};
