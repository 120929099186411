import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { APP_SECTIONS } from "@/constants/appSections";
import { Environment, ENVIRONMENTS } from "@/models/user";

import { api } from "../../app/services/api";
import { RootState } from "../../app/store";

export type ActiveGame = {
  code: string;
  name: string;
};

type AppConfig = {
  activeSection: (typeof APP_SECTIONS)[keyof typeof APP_SECTIONS];
  activeGame: ActiveGame | null;
  activeEnvs: {
    source: Environment;
    target: Environment;
  };
};

export const GLOBAL_SELECTION = {
  code: "global",
  name: "Global",
};

type State = {
  navigationOpen: boolean;
  appConfig: AppConfig;
};

const initialState: State = {
  navigationOpen: true,
  appConfig: {
    activeSection: "general",
    activeGame: null,
    activeEnvs: {
      source: ENVIRONMENTS.migrator,
      target: ENVIRONMENTS.migrator,
    },
  },
};

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setNavigationOpen: (state, action: PayloadAction<boolean>) => {
      state.navigationOpen = action.payload;
    },
    setAppConfig: (state, action: PayloadAction<Partial<AppConfig>>) => {
      state.appConfig = { ...state.appConfig, ...action.payload };
    },
    setAppSection: (state, action) => {
      state.appConfig.activeSection = action.payload;
    },
  },
});

export const setActiveAppConfig = createAsyncThunk<void, Partial<AppConfig>>(
  `${slice.name}/setActiveAppConfig`,
  async (payload, { dispatch }) => {
    dispatch(slice.actions.setAppConfig(payload));

    // Reset API state which will re-fetch all data
    dispatch(api.util.resetApiState());
  }
);

const persistConfig = {
  key: slice.name,
  version: 1,
  storage,
};

const { reducer } = slice;
export default persistReducer(persistConfig, reducer);

export const { setNavigationOpen, setAppSection, setAppConfig } = slice.actions;

export const selectIsNavigationOpen = (state: RootState) =>
  state.general.navigationOpen;

export const selectAppConfig = (state: RootState) => {
  return state.general.appConfig;
};
