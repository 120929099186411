import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const RecurringLoginTemplateDiffList = React.lazy(
  () => import("@/pages/RecurringLoginTemplate/RecurringLoginTemplateDiffList")
);
const RecurringLoginTemplateDiff = React.lazy(
  () => import("@/pages/RecurringLoginTemplate/RecurringLoginTemplateDiff")
);

export const RECURRING_LOGIN_TEMPLATE_ROUTES = {
  recurringLoginTemplates: {
    path: "recurring-login-templates",
    childRoutes: {
      recurringLoginTemplateList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.recurringLoginTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <RecurringLoginTemplateDiffList />,
      },
      recurringLoginTemplateDiff: {
        path: ":recurringLoginTemplateDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.recurringLoginTemplate],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <RecurringLoginTemplateDiff />,
      },
    },
  },
};
