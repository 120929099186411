import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const LevelOrderDiffList = React.lazy(
  () => import("@/pages/LevelOrder/LevelOrderDiffList")
);
const LevelOrderDiff = React.lazy(
  () => import("@/pages/LevelOrder/LevelOrderDiff")
);

export const LEVEL_ORDER_ROUTES = {
  levelOrder: {
    path: "level-order",
    childRoutes: {
      levelOrderDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.levelOrder],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LevelOrderDiffList />,
      },
      levelOrderDiff: {
        path: ":levelOrderDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.levelOrder],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <LevelOrderDiff />,
      },
    },
  },
};
