import React from "react";

import { APP_ROUTES } from "@/routes/app";
import { ASSET_ROUTES } from "@/routes/asset";
import { ASSET_TYPE_ROUTES } from "@/routes/assetType";
import { COUNTRY_ROUTES } from "@/routes/country";
import { CURRENCY_ROUTES } from "@/routes/currency";
import { CURRENCY_QUEST_BOARD_TEMPLATE_ROUTES } from "@/routes/currencyQuestBoardTemplate";
import { CURRENCY_QUEST_TEMPLATE_ROUTES } from "@/routes/currencyQuestTemplate";
import { EPISODE_ROUTES } from "@/routes/episode";
import { EPISODE_ORDER_ROUTES } from "@/routes/episodeOrder";
import { GAME_KEY_ROUTES } from "@/routes/gameKey";
import { GAME_SETTING_ROUTES } from "@/routes/gameSetting";
import { LADDER_TEMPLATE_ROUTES } from "@/routes/ladderTemplate";
import { LEVEL_ROUTES } from "@/routes/level";
import { LEVEL_ORDER_ROUTES } from "@/routes/levelOrder";
import { LEVEL_TEMPLATE_ROUTES } from "@/routes/levelTemplate";
import { MESSAGE_TEMPLATE_ROUTES } from "@/routes/messageTemplate";
import { PLATFORM_ROUTES } from "@/routes/platform";
import { PRODUCT_ROUTES } from "@/routes/product";
import { RECURRING_LOGIN_TEMPLATE_ROUTES } from "@/routes/recurringLoginTemplate";
import { ROLE_DIFF_ROUTES } from "@/routes/roleDiff";
import { SHOP_ROUTES } from "@/routes/shop";
import { SHOP_SELECTOR_ROUTES } from "@/routes/shopSelector";
import { TASK_ROUTES } from "@/routes/task";
import { TILE_TEMPLATE_ROUTES } from "@/routes/tileTemplate";
import { TIMED_OFFER_ROUTES } from "@/routes/timedOffer";
import { TIMED_SHOP_SELECTOR_OVERRIDE_ROUTES } from "@/routes/timedShopSelectorOverride";
import { TRANSACTION_MODIFIER_TEMPLATE_ROUTES } from "@/routes/transactionModifierTemplate";

const Homepage = React.lazy(() => import("@/pages/Homepage/Homepage"));

export const MIGRATOR_ROUTES = {
  home: {
    id: "home",
    index: true,
    element: <Homepage />,
  },
  ...APP_ROUTES,
  ...COUNTRY_ROUTES,
  ...PRODUCT_ROUTES,
  ...CURRENCY_ROUTES,
  ...CURRENCY_QUEST_BOARD_TEMPLATE_ROUTES,
  ...CURRENCY_QUEST_TEMPLATE_ROUTES,
  ...GAME_KEY_ROUTES,
  ...GAME_SETTING_ROUTES,
  ...LADDER_TEMPLATE_ROUTES,
  ...MESSAGE_TEMPLATE_ROUTES,
  ...PLATFORM_ROUTES,
  ...ROLE_DIFF_ROUTES,
  ...SHOP_ROUTES,
  ...SHOP_SELECTOR_ROUTES,
  ...TILE_TEMPLATE_ROUTES,
  ...TIMED_OFFER_ROUTES,
  ...TIMED_SHOP_SELECTOR_OVERRIDE_ROUTES,
  ...TRANSACTION_MODIFIER_TEMPLATE_ROUTES,
  ...RECURRING_LOGIN_TEMPLATE_ROUTES,
  ...ASSET_ROUTES,
  ...ASSET_TYPE_ROUTES,
  ...EPISODE_ROUTES,
  ...EPISODE_ORDER_ROUTES,
  ...LEVEL_ROUTES,
  ...LEVEL_ORDER_ROUTES,
  ...LEVEL_TEMPLATE_ROUTES,
  ...TASK_ROUTES,
};
