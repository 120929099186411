import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  handleLogout,
  selectRefreshExpiresIn,
} from "../features/auth/authSlice";
import { enqueueSnackbar } from "../features/notifier/notifierSlice";

export default function useTokenExpireLogout() {
  const expiration = useAppSelector(selectRefreshExpiresIn);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // Logged out
    if (expiration === null) {
      return;
    }

    // Warn user of expiring session in 5 minutes
    let warningTimeout: NodeJS.Timeout | undefined;
    const warningTime = expiration.getTime() - Date.now() - 1000 * 60 * 5;
    if (warningTime > 0) {
      warningTimeout = setTimeout(() => {
        dispatch(
          enqueueSnackbar({
            message: "Login session will expire in 5 minutes...",
            options: {
              key: "login_expiring_5_minutes",
              variant: "warning",
            },
          })
        );
      }, warningTime);
    }

    // Warn user of expiring session in 1 minute
    let minuteWarningTimeout: NodeJS.Timeout | undefined;
    const minuteWarningTime = expiration.getTime() - Date.now() - 1000 * 60;
    if (minuteWarningTime > 0) {
      minuteWarningTimeout = setTimeout(() => {
        dispatch(
          enqueueSnackbar({
            message: "Login session will expire in 1 minute...",
            options: {
              key: "login_expiring_1_minute",
              variant: "warning",
            },
          })
        );
      }, minuteWarningTime);
    }

    // Logout user when session expires
    const sessionTimeout = setTimeout(() => {
      dispatch(handleLogout());

      dispatch(
        enqueueSnackbar({
          message: "Login session expired, logging you out...",
          options: {
            key: "login_expired",
            variant: "error",
          },
        })
      );
    }, expiration.getTime() - Date.now());

    // Cancel timeouts when component unmounts
    return () => {
      if (warningTimeout) {
        clearTimeout(warningTimeout);
      }

      if (minuteWarningTimeout) {
        clearTimeout(minuteWarningTimeout);
      }

      clearTimeout(sessionTimeout);
    };
  }, [expiration, dispatch]);

  return null;
}
