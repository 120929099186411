import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { UserRole } from "@/models/user";

import { authApi } from "../../app/services/auth";
import { RootState } from "../../app/store";
import { Session } from "../../models/session";
import { enqueueSnackbar } from "../notifier/notifierSlice";

type State = {
  username: string | null;
  roleData: UserRole | null;
} & Session;

const initialState: State = {
  username: null,
  access_token: null,
  expires_in: null,
  refresh_expires_in: null,
  refresh_token: null,
  token_type: null,
  not_before_policy: null,
  session_state: null,
  scope: [],
  roleData: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUsername: (state, action: PayloadAction<string | null>) => {
      state.username = action.payload;
    },
    setRoleData: (state, action: PayloadAction<UserRole | null>) => {
      state.roleData = action.payload;
    },
    handleLogout: (state) => {
      state.username = null;
      state.access_token = null;
      state.expires_in = null;
      state.refresh_expires_in = null;
      state.refresh_token = null;
      state.token_type = null;
      state.not_before_policy = null;
      state.session_state = null;
      state.scope = [];
      state.roleData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        authApi.endpoints.login.matchFulfilled,
        authApi.endpoints.refreshLogin.matchFulfilled
      ),
      (state, action) => {
        state.access_token = action.payload.access_token;
        state.expires_in = action.payload.expires_in;
        state.refresh_expires_in = action.payload.refresh_expires_in;
        state.refresh_token = action.payload.refresh_token;
        state.token_type = action.payload.token_type;
        state.not_before_policy = action.payload.not_before_policy;
        state.session_state = action.payload.session_state;
        state.scope = action.payload.scope;
      }
    );
  },
});

export const logout = createAsyncThunk<void, void>(
  `${slice.name}/logout`,
  async (_, { dispatch }) => {
    await dispatch(slice.actions.handleLogout());

    dispatch(
      enqueueSnackbar({
        message: `Logged out successfully!`,
        options: {
          key: "logout_success",
          variant: "success",
        },
      })
    );
  }
);

const persistConfig = {
  key: slice.name,
  version: 1,
  storage,
};

const { reducer } = slice;
export default persistReducer(persistConfig, reducer);

export const { setUsername, handleLogout, setRoleData } = slice.actions;

export const selectUsername = (state: RootState) => state.auth.username;

export const selectIsAuthenticated = (state: RootState) =>
  !!state.auth.access_token;

export const selectExpiresIn = (state: RootState) =>
  state.auth.expires_in ? new Date(state.auth.expires_in) : null;

export const selectUserPermissions = (state: RootState) =>
  state.auth.roleData?.access || null;

export const selectHasTokenExpired = (state: RootState) => {
  const expiresIn = selectExpiresIn(state);
  return !expiresIn || expiresIn.getTime() < Date.now();
};

export const selectRefreshExpiresIn = (state: RootState) =>
  state.auth.refresh_expires_in
    ? new Date(state.auth.refresh_expires_in)
    : null;
export const selectHasRefreshTokenExpired = (state: RootState) => {
  const refreshExpiresIn = selectRefreshExpiresIn(state);
  return !refreshExpiresIn || refreshExpiresIn.getTime() < Date.now();
};
