import React from "react";

import { RESOURCES, RESOURCE_OPERATIONS } from "@/models/user";

const TimedOfferDiffList = React.lazy(
  () => import("@/pages/TimedOffer/TimedOfferDiffList")
);
const TimedOfferDiff = React.lazy(
  () => import("@/pages/TimedOffer/TimedOfferDiff")
);

export const TIMED_OFFER_ROUTES = {
  timedOffers: {
    path: "timed-offers",
    childRoutes: {
      timedOfferDiffList: {
        index: true,
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.timedOffer],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TimedOfferDiffList />,
      },
      timedOfferDiff: {
        path: ":timedOfferDiffId",
        isProtected: true,
        permissionProps: {
          resources: [RESOURCES.timedOffer],
          operations: [RESOURCE_OPERATIONS.read],
        },
        element: <TimedOfferDiff />,
      },
    },
  },
};
